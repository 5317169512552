import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './subscribe.css';

export default () => {
    const [playing, setPlaying] = useState(false);

    return (
    <Layout>
        <SEO title="Subscribe to SalesTribe" />
        <div id="container">
            <div id="hero">
             <div id="hero-content">
            <h1>Subscribe to SalesTribe</h1>
            <h3>Get access to our world leading program<b><br />Sales Success with Social Media</b> <br />for an entire year</h3>

            <div className="pricing">
               <h1 id="pricing-text"><span id="old-price">$75</span> $39<span className="currency">monthly</span></h1>
               <h3 className="monthly-price">for a minimum of 12 months</h3>
            </div>

            <a href="https://members.salestribe.com/signup" className="btn btn-default hero-btn">Get started!</a>

                <div id="video-container">
                {/* {!playing && <a id="play-video" onClick={(e) => {
                    e.preventDefault(); 
                    setPlaying(true);
                    }} className="video-play-button" href="#">
                <span></span>
                </a>} */}

            {/* <img id="hero-video" src="media/images/article-challenge-reach.2e16d0ba.fill-1162x655.jpg"></img> */}
            <iframe id="hero-video" width="560" height="315" src="https://www.youtube.com/embed/197x4R1kHgo?&controls=1&modestbranding=1&enablejsapi=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <video controls={playing} id="hero-video">
                <source src="media/videos/subscribe_landing.webm" type="video/webm" />   
            </video> */}
            </div>

            </div>
            <div className="hero-subheading">
            {/* <h3>That's a total cost of $466 (AUD) over 12 months - around 2 coffees per week.</h3> */}
            </div>  

            </div>  
            <div className="features-section">
                <h2>What do you get access to?</h2>
                <div className="features">
                    <div className="feature">
                    <img alt="SalesTribe assessment feature" src="/media/svg/landing-552x580_knowledgecentre-insights.svg"/>
                    <h3>Personal<br/>Assessment</h3>
                    <p>Your first step to becoming SalesTribe certified is to complete our assessment questionnaire.</p>
                    </div>
                    <div className="feature">
                    <img alt="SalesTribe learning feature" src="/media/svg/landing-552x580_knowledgecentre-library.svg"/>
                    <h3>Certification<br/> Courses</h3>
                    <p>Looking to improve your sales skills? Complete our SalesTribe certification courses.</p>
                    </div>
                    <div className="feature">
                    <img alt="SalesTribe coaching feature" src="/media/svg/landing-256x183_sales-professionals.svg"/>
                    <h3>Coaching<br/> sessions</h3>
                    <p>Book a time for one on one coaching with our SalesTribe team. They'll go over your assessment and field your questions.</p>
                    </div>
                </div>
            </div>

        <div className="cta-section">
            <h2>Get certified today</h2>
            <h3>Let us help you protect and upgrade your career in sales.</h3>
            <div className="cta-button">
                <a href="https://members.salestribe.com/signup" className="btn btn-default cta-btn hero-btn">Subscribe</a>
            </div>
            {/* <img alt="SalesTribe certification" src="media/images/sales-certification.png"/> */}
        </div>
        </div>
    </Layout>
)}